<template>
  <div class="popup__player">
    <div class="popup__player-play" :class="{pause: isPlaying}" @click="togglePlay" v-show="isReady"></div>
    <canvas class="popup__player-track" ref="audioCanvas" @click="seek"></canvas>
    <div class="popup__player-duration" v-show="isReady">{{ isPlaying ? formattedCurrentTime : formattedDuration }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      audio: null,
      duration: 0,
      currentTime: 0,
      isPlaying: false,
      playedPercentage: 0,
      isReady: false,
    };
  },
  props: {
    track: {
      type: Number
    }
  },
  computed: {
    formattedDuration() {
      let minutes = Math.floor(this.duration / 60);
      let seconds = Math.floor(this.duration % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },
    formattedCurrentTime() {
      let minutes = Math.floor(this.currentTime / 60);
      let seconds = Math.floor(this.currentTime % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },
  },
  methods: {
    togglePlay() {
      this.isPlaying ? this.audio.pause() : this.audio.play();
      this.isPlaying = !this.isPlaying;
    },
    drawWaveform(dataArray) {
      if (!dataArray) {
        return;
      }
      const canvas = this.$refs.audioCanvas;
      const ctx = canvas.getContext('2d');
      const width = canvas.width;
      const height = canvas.height;
      const barWidth = 2;
      const barGap = 2;
      const totalBarWidth = barWidth + barGap;
      const barsCount = Math.floor(width / totalBarWidth);

      ctx.clearRect(0, 0, width, height);

      const step = Math.ceil(dataArray.length / barsCount);
      const amp = height / 2;
      for (let i = 0; i < barsCount; i++) {
        let min = 1.0;
        let max = -1.0;
        for (let j = 0; j < step; j++) {
          const datum = dataArray[(i * step) + j];
          if (datum < min)
            min = datum;
          if (datum > max)
            max = datum;
        }
        const barHeight = (max - min) * amp;
        ctx.fillStyle = i * totalBarWidth / width <= this.playedPercentage ? '#ffffff' : '#FFB49A';
        ctx.fillRect(i * totalBarWidth, height - barHeight, barWidth, barHeight);
      }
      this.isReady = true;
    },
    seek(event) {
      const percentage = event.offsetX / event.target.clientWidth;
      if (this.audio) {
        this.audio.currentTime = percentage * this.audio.duration;
      }
      this.playedPercentage = percentage;
      this.drawWaveform(this.leftChannel);
    }
  },
  watch: {
    'audio.currentTime'() {
      if (this.audio) {
        this.playedPercentage = this.audio.currentTime / this.audio.duration;
      }
      this.drawWaveform(this.leftChannel);
    }
  },
  async mounted() {
    if (this.track == 1) {
      this.audio = new Audio(require('@/assets/sounds/track_1.mp3'));
    }
    if (this.track == 2) {
      this.audio = new Audio(require('@/assets/sounds/track_2.mp3'));
    }
    await this.audio.load();

    this.audio.addEventListener('timeupdate', () => {
      if (this.audio) {
        this.currentTime = this.audio.currentTime;
        this.playedPercentage = this.audio.currentTime / this.audio.duration;
      }
      this.drawWaveform(this.leftChannel);
    });
    this.audio.addEventListener('loadedmetadata', () => {
        this.duration = this.audio.duration;
    });

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const source = audioContext.createBufferSource();
    const request = new XMLHttpRequest();
    request.open('GET', require('@/assets/sounds/symphony.mp3'), true);
    request.responseType = 'arraybuffer';

    request.onload = () => {
      audioContext.decodeAudioData(request.response, (buffer) => {
        source.buffer = buffer;

        this.leftChannel = buffer.getChannelData(0);
        this.drawWaveform(this.leftChannel);
      });
    };
    request.send();
  },
  beforeDestroy() {
    this.audio.pause();
    this.audio = null;
  }
};
</script>
