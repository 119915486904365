<template>
	<div class="welcome__ref">
		<div class="welcome__bg"></div>
		<div class="welcome__box">
			<div class="welcome__intro">
				<!-- <div class="welcome__day-night"></div> -->
				<div class="welcome__menu"></div>
				<div class="welcome__sound"></div>
				<div class="welcome__play"></div>
				<div class="welcome__close-info" @click="$emit('closeRef')"></div>
				<div class="welcome__line">
					<!-- <div class="move"></div> -->
					<div class="hanlder">1963</div>
					<span></span>
				</div>
				<!-- <div class="welcome__hint">
					<span></span>
					Здесь можно поменять режим <br>с дневного <br class="mob-only">на ночной и наоборот
				</div> -->
				<transition>
					<div class="welcome__hint welcome__hint--2 desk-only">
						<span></span>
						<em>Это меню</em>
						<p>Здесь можно моментально <br><br class="mob-only">перейти к любой эпохе и дате</p>
					</div>
				</transition>
				<transition>
					<div class="welcome__hint welcome__hint--2 mob-only">
						<em>Меню</em>
					</div>
				</transition>
				<transition>
					<div class="welcome__hint welcome__hint--3 desk-only">
						<span></span>
						<em>Это что-то <br>интересное</em>
						<p>Нажимай на анимированные <br>объекты, чтобы сыграть в игру <br>или участвовать в викторине</p>
					</div>
				</transition>
				<transition>
					<div class="welcome__hint welcome__hint--3 mob-only">
						<em>Жми сюда, <br class="mob-only">здесь что-то интересное</em>
					</div>
				</transition>
				<transition>
					<div class="welcome__hint welcome__hint--4 desk-only">
						<span></span>
						<em>Это таймлайн</em>
						<p>Выбери эпоху, которая тебе <br>нравится, чтобы узнать о ней <br>больше</p>
					</div>
				</transition>
				<transition>
					<div class="welcome__hint welcome__hint--4 mob-only">
						<em>Таймлайн</em>
					</div>
				</transition>
				<transition>
					<div class="welcome__hint welcome__hint--5 desk-only">
						<span></span>
						<em>Это звук</em>
						<p>Не забудь надеть наушники, <br class="mob-only">если <br>ты находишься в офисе <br class="mob-only">или <br>общественном <br class="mob-only">пространстве</p>
					</div>
				</transition>
				<transition>
					<div class="welcome__hint welcome__hint--5 mob-only">
						<em>Звук</em>
					</div>
				</transition>
				<transition>
					<div class="welcome__hint welcome__hint--6 desk-only">
						<span></span>
						<em>Это плеер</em>
						<p>Нажми на «Пуск» и залипай <br>на путешествие во времени <br>сколько тебе угодно</p>
					</div>
				</transition>
				<transition>
					<div class="welcome__hint welcome__hint--6 mob-only">
						<em>Плеер</em>
					</div>
				</transition>
				<!-- <transition>
					<div class="welcome__title">Что здесь происходит?</div>
				</transition>
				<transition>
					<div class="welcome__text">
						Добро пожаловать в бесконечную ленту технологий, где мы собрали все самые значимые разработки своего времени. Узнай больше <br>о мире инноваций, который тебя окружает, путешествуя во времени, играя в мини-игры и участвуя в викторинах!
					</div>
				</transition>
				<transition>
					<a href="javascript:void(0)" class="welcome__button welcome__button--2 desk-only" @click="$emit('closeRef')">Интересно, поехали!</a>
				</transition>
				<transition>
					<a href="javascript:void(0)" class="welcome__button welcome__button--2 mob-only" @click="$emit('closeRef')">Мне всё понятно</a>
				</transition> -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Info",
	}
</script>