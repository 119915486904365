<template>
	<div class="quiz__window" :class="{'quiz__window--image': question.image}"  :style="`background-image: url(${getImage})`">
		<div class="quiz__window-content">
			<div class="quiz__window-title">Вопрос {{step}}/{{total}}</div>
			<div class="quiz__window-text" v-html="question.question"></div>
			<div class="quiz__window-items">
				<div class="quiz__window-item" v-for="(answer, index) in question.answers" :key="index" @click="setAnswer(index)">
					<div class="quiz__window-checkbox" :class="{'quiz__window-checkbox--active': choise == index}">
					</div>
					<div class="quiz__window-label">
						{{answer.text}}
					</div>
				</div>
			</div>
			<div class="quiz__window-bottom">
				<a href="javascript:void(0)" class="quiz__window-btn quiz__window-btn--disabled" data-tippy-content="Выберите вариант ответа" v-if="choise === null" id="disabled_button">продолжить</a>
				<a href="javascript:void(0)" class="quiz__window-btn" @click="submit" v-else id="ready_button">продолжить</a>
			</div>
		</div>
	</div>
</template>

<script>
	import tippy, {followCursor} from 'tippy.js';
	import 'tippy.js/dist/tippy.css'; 
	import 'tippy.js/themes/light.css'; 
	export default {
		name: "Quiz_2_1",
		data() {
			return {
				choise: null,
				tippy: null,
			}
		},
		props: {
			question: {
				type: Object,
				required: true,
			},
			step: {
				type: Number,
				required: true,
			},
			total: {
				type: Number,
				required: true,
			},
		},
		mounted() {
			this.initTippy();
		},
		methods: {
			initTippy() {
				this.tippy = tippy('[data-tippy-content]', {
					zIndex: 9999999999,
					followCursor: true,
					plugins: [followCursor],
					theme: 'light',
				});
			},
			setAnswer(index) {
				this.choise = index;
				this.tippy[0].destroy();
			},
			submit() {
				if (this.choise !== null) {
					this.$emit('next', this.question.answers[this.choise]);
					this.choise = null;
					this.initTippy();
				}
			}
		},
		computed: {
			getImage() {
				if (this.question.image) {
					return require('../../assets/img/quiz/'+this.question.image+'.jpg');
				} else {
					return null;
				}
			}
		}
	}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/quiz.sass'
.quiz__window-text
	margin-top: vw(40px) !important
.quiz__window-items
	flex-direction: column
	padding: 0 vw(25px) 0 vw(25px)
.quiz__window-label 
	text-align: start	
.quiz__window-item
	margin-bottom: vw(20px)
.quiz__window-btn--disabled
	opacity: 0.4
</style>