<template>
    <div class="wrapper-quiz">
        <Quiz_1 />
        <Quiz_2 />
        <Quiz_2_1 />
        <Quiz_2_2 />
        <Quiz_2_3 />
        <Quiz_2_4 />
        <Quiz_3 />
        <Quiz_3_1 />
        <Quiz_3_2 />
        <Quiz_4 />
        <Quiz_4_1 />
    </div>

</template>

<script>
import Quiz_1 from '@/components/Quiz/Quiz_1'
import Quiz_2 from '@/components/Quiz/Quiz_2'
import Quiz_2_1 from '@/components/Quiz/Quiz_2_1'
import Quiz_2_2 from '@/components/Quiz/Quiz_2_2'
import Quiz_2_3 from '@/components/Quiz/Quiz_2_3'
import Quiz_2_4 from '@/components/Quiz/Quiz_2_4'
import Quiz_3 from '@/components/Quiz/Quiz_3'
import Quiz_3_1 from '@/components/Quiz/Quiz_3_1'
import Quiz_3_2 from '@/components/Quiz/Quiz_3_2'
import Quiz_4 from '@/components/Quiz/Quiz_4'
import Quiz_4_1 from '@/components/Quiz/Quiz_4_1'
export default {
	name: 'Quiz',
	components: {
		Quiz_1,
        Quiz_2,
        Quiz_2_1,
        Quiz_2_2,
        Quiz_2_3,
        Quiz_2_4,
        Quiz_3,
        Quiz_3_1,
        Quiz_3_2,
        Quiz_4,
        Quiz_4_1,
	},
	data() {
		return {
		}
	}
}
</script>

<style lang="sass">
.wrapper-quiz
    display: flex
    flex-wrap: wrap
    width: 100%
    height: auto
    background-color: rgba(25, 0, 53, 0.84)
</style>
