<template>
	<div class="quiz__window">
		<div class="quiz__window-content">
			<div class="quiz__window-title">Ответ</div>
			<div class="quiz__window-text">Да, такие соединения назывались проводами. Но уже вначале 2000-х активно начали развиваться технологии беспроводной связи. А к 2050 году благодаря внедрению «Ростелекомом» новых инструментов беспроводных коммуникаций в России уже практически не использовались такие соединения.</div>
			<div class="quiz__window-bottom">
				<a href="javascript:void(0)" class="quiz__window-btn">далее</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Quiz_3_2",
	}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/quiz.sass'
.quiz__window-bottom 
	margin-top: vw(40px)
	display: flex
	justify-content: space-around
.quiz__window-text
	margin-top: vw(40px)
</style>