<template>
	<div class="quiz__window">
		<div class="quiz__window-content">
			<div class="quiz__window-title">Вопрос 1/5</div>
			<div class="quiz__window-text">Правда ли, что когда-то связь обеспечивалась посредствам длинных чёрных соединений, похожих на верёвки?</div>
			<div class="quiz__window-bottom">
				<a href="javascript:void(0)" class="quiz__window-btn">узнать ответ</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Quiz_2_3",
	}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/quiz.sass'
.quiz__window-bottom 
	margin-top: vw(40px)
	display: flex
.quiz__window-text
	margin-top: vw(40px)
</style>