<template>
	<div class="viewer">
		<div id="viewer"></div>
	</div>
</template>

<script>
	import {createTelegraphViewer} from '@/game/games-lib.js'
	import {createGrahamViewer} from '@/game/games-lib.js'
	import {createTaxphoneViewer} from '@/game/games-lib.js'
	import {createRadioViewer} from '@/game/games-lib.js'
	import {createTarelkaViewer} from '@/game/games-lib.js'
	import {createSputnikViewer} from '@/game/games-lib.js'
	import {createMarusyaViewer} from '@/game/games-lib.js'
	import {createUssrTelecomViewer} from '@/game/games-lib.js'
	import {createNokiaViewer} from '@/game/games-lib.js'
	export default {
		name: "Viewer",
		props: {
			id: {
				type: Number ,
			}
		},
		mounted() {
			const container = document.getElementById('viewer')
			if (this.id == 1) {
				this.viewer = createTelegraphViewer(container)
			}
			if (this.id == 2) {
				this.viewer = createGrahamViewer(container)
			}
			if (this.id == 3) {
				this.viewer = createTaxphoneViewer(container)
			}
			if (this.id == 4) {
				this.viewer = createRadioViewer(container)
			}
			if (this.id == 5) {
				this.viewer = createTarelkaViewer(container)
			}
			if (this.id == 6) {
				this.viewer = createSputnikViewer(container)
			}
			if (this.id == 7) {
				this.viewer = createMarusyaViewer(container)
			}
			if (this.id == 8) {
				this.viewer = createUssrTelecomViewer(container)
			}
			if (this.id == 9) {
				this.viewer = createNokiaViewer(container)
			}
		},
		destroyed() {
			this.viewer.unloadGame();
		}
	}
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	.viewer
		display: flex
		align-items: center
		justify-content: center
		background-color: #dadada
		width: 100%
		height: 100%
	#viewer
		width: 100%
		height: 100%
</style>