<template>
	<div class="quiz__window" :class="{'quiz__window--image': image}"  :style="`background-image: url(${getImage})`">
		<div class="quiz__window-content">
			<div class="quiz__window-title" v-if="result == 'correct'">Отлично!</div>
			<div class="quiz__window-title quiz__window-title--false" v-else>Истина где-то рядом</div>
			<div class="quiz__window-text" v-if="result == 'correct'" v-html="success"></div>
			<div class="quiz__window-text" v-else v-html="fail"></div>
			<div class="quiz__window-bottom">
				<a href="javascript:void(0)" class="quiz__window-btn" @click="$emit('restart')">попробовать ещё раз</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Quiz_4",
		props: {
			result: {
				type: String,
				required: true,
			},
			fail: {
				type: String,
				required: true,
			},
			success: {
				type: String,
				required: true,
			},
			image: {
				type: Number,
				required: false,
				default: null,
			},
		},
		computed: {
			getImage() {
				if (this.image) {
					return require('../../assets/img/quiz/'+this.image+'.jpg');
				} else {
					return null;
				}
			}
		}
	}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/quiz.sass'
.quiz__window-bottom 
	margin-top: vw(40px)
	display: flex
	justify-content: space-around
.quiz__window-text
	margin-top: vw(40px)
</style>