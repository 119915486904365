<template>
	<div class="game">
		<div id="game"></div>
	</div>
</template>

<script>
	import {createTelegraphGame} from '@/game/games-lib.js'
	import {createWiresGame} from '@/game/games-lib.js'
	import {createNinjaGame} from '@/game/games-lib.js'
	import {createEgeGame} from '@/game/games-lib.js'
	import {createTaxophoneGame} from '@/game/games-lib.js'
	import {createSputnikGame} from '@/game/games-lib.js'
	import {createTrucksGame} from '@/game/games-lib.js'
	import {createBiometryGame} from '@/game/games-lib.js'
	import {createLookSharpGame} from '@/game/games-lib.js'
	import {createMapsGame} from '@/game/games-lib.js'
	import {createATSGame} from '@/game/games-lib.js'
	import {createPagerGame} from '@/game/games-lib.js'
	import {createMarusiaGame} from '@/game/games-lib.js'
	import {createNeuroQuizGame} from '@/game/games-lib.js'
	import {createBigDataGame} from '@/game/games-lib.js'
	import {createRoomGame} from '@/game/games-lib.js'
	export default {
		name: "Game",
		props: {
			id: {
				type: Number ,
			}
		},
		mounted() {
			const container = document.getElementById('game')
			if (this.id == 1) {
				this.game = createTelegraphGame(container)
			}
			if (this.id == 2) {
				this.game = createTaxophoneGame(container)
			}
			if (this.id == 3) {
				this.game = createSputnikGame(container)
			}
			if (this.id == 4) {
				this.game = createWiresGame(container)
			}
			if (this.id == 5) {
				this.game = createEgeGame(container)
			}
			if (this.id == 6) {
				this.game = createTrucksGame(container)
			}
			if (this.id == 7) {
				this.game = createNinjaGame(container)
			}
			if (this.id == 8) {
				this.game = createBiometryGame(container)
			}
			if (this.id == 9) {
				this.game = createLookSharpGame(container)
			}
			if (this.id == 10) {
				this.game = createMapsGame(container)
			}
			if (this.id == 11) {
				this.game = createATSGame(container)
			}
			if (this.id == 12) {
				this.game = createPagerGame(container)
			}
			if (this.id == 13) {
				this.game = createMarusiaGame(container)
			}
			if (this.id == 14) {
				this.game = createNeuroQuizGame(container)
			}
			if (this.id == 15) {
				this.game = createBigDataGame(container)
			}
			if (this.id == 16) {
				this.game = createRoomGame(container)
			}
		},
		destroyed() {
			this.game.unloadGame();
		}
	}
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	.game
		display: flex
		align-items: center
		justify-content: center
		background-color: #dadada
		height: 100%
		@media screen and (max-width: 1000px)
			height: vwm(352px)
		&__title
			font-size: vw(20px)
	#game
		width: 100%
		height: 100%
		position: relative
		overflow: hidden
</style>