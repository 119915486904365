<template>
  <div class="popup__bg">
    <div class="popup w-1140">
      <div class="popup__container">
        <div class="popup__interactive">
          <PhotoEditor
            @change="changeStep"
            :selectedImage="selectedImage"
            :result="result"
            :class="{ 'blur-main-photo': isWaiting }"
          />
        </div>
        <div class="popup__article" data-lenis-prevent>
          <div class="popup__article-content popup__article--step-1" v-if="step == 1">
            <div
              class="popup__title"
              v-html="activeContent.title"
              :class="{
                'popup__title--small': activeContent.small_title,
                'popup__title--mini': activeContent.mini_title,
                'popup__title--small-mobile': activeContent.small_title_mobile,
                'popup__title--mini-mobile': activeContent.mini_title_mobile,
              }"
            ></div>
            <div class="popup__subtitle" v-html="activeContent.info"></div>
            <div class="popup__text" v-html="activeContent.text"></div>
          </div>
          <div class="popup__article-content" v-if="step == 2">
            <div class="popup__title popup__steps">Шаг 1/3</div>
            <div
              class="popup__title"
              :class="{
                'popup__title--small': activeContent.small_title,
                'popup__title--mini': activeContent.mini_title,
                'popup__title--small-mobile': activeContent.small_title_mobile,
                'popup__title--step-2': isStarted,
              }"
            >
              Загрузите <br />своё фото
            </div>
            <div class="popup__subtitle" style="padding-right: 0px">
              Для того, чтобы достичь наилучшего результата при создании
              аватара&nbsp;—&nbsp;придерживайтесь следующих правил:
            </div>
            <div class="popup__text popup__text--mini" v-if="!error">
              1. Убедитесь, что на фотографии нет посторонних людей <br />
              2. Ваше лицо хорошо видно <br />
              3. Избегайте плохого освещения <br />
            </div>
            <div class="popup__editor">
              <div class="popup__error" v-if="error">
                Фотография не распознана. Вероятно, она не отвечает минимальным
                требованиям для дальнейшей обработки.
              </div>
              <div class="popup__text" v-if="error">
                Попробуйте еще раз или загрузите более <br />
                качественное фото.
              </div>
              <div class="popup__editor-button" v-if="!selectedImage">
                <input
                  type="file"
                  ref="fileInput"
                  class="popup__editor-input"
                  @change="handleFileUpload"
                />
                <a
                  href="javascript:void(0)"
                  class="popup__editor-button-link"
                  @click="triggerFileUpload"
                  v-if="!isValidation"
                >
                  <i> <img src="@/assets/img/upload.svg" alt="" /> </i>Загрузить фото
                </a>
                <div class="popup__loading" v-if="isValidation">
                  <div class="popup__loading-line">
                    <div
                      class="popup__loading-line-progress"
                      :style="`width: ${percent / 2}%`"
                    ></div>
                  </div>
                  <div class="popup__loading-title">Ожидайте...</div>
                </div>
              </div>
            </div>
          </div>
          <div class="popup__article-content" v-if="step == 3">
            <div class="popup__title popup__steps">Шаг 2/3</div>
            <div
              class="popup__title"
              :class="{
                'popup__title--small': activeContent.small_title,
                'popup__title--mini': activeContent.mini_title,
                'popup__title--small-mobile': activeContent.small_title_mobile,
                'popup__title--step-2': isStarted,
              }"
              v-if="!isWaiting"
            >
              Укажите пол <br />и выберите фильтр
            </div>
            <div
              class="popup__title"
              :class="{
                'popup__title--small': activeContent.small_title,
                'popup__title--mini': activeContent.mini_title,
                'popup__title--small-mobile': activeContent.small_title_mobile,
                'popup__title--step-2': isStarted,
              }"
              v-else
            >
              Укажите пол <br />и выберите фильтр
            </div>
            <div class="popup__subtitle popup__subtitle--orange">
              Выберите один из двух вариантов
            </div>
            <div class="popup__radio">
              <div
                class="popup__radio-item"
                :class="{ active: sex == 'male' }"
                @click="setSex('male')"
              >
                <div class="popup__radio-item-round"><span></span></div>
                <div class="popup__radio-item-title">Мужской</div>
              </div>
              <div
                class="popup__radio-item"
                :class="{ active: sex == 'female' }"
                @click="setSex('female')"
              >
                <div class="popup__radio-item-round"><span></span></div>
                <div class="popup__radio-item-title">Женский</div>
              </div>
            </div>
            <div class="popup__subtitle popup__subtitle--orange">
              Выберите понравившийся фильтр
            </div>
            <div class="popup__editor">
              <div class="popup__variants" v-if="sex == 'male'">
                <div
                  class="popup__variants-item"
                  @click="setType('rtk_present_male')"
                  :class="{ active: type == 'rtk_present_male', waiting: isWaiting }"
                >
                  <img src="@/assets/img/anime.png" alt="" />
                  <p>Комикс <br />портрет</p>
                </div>
                <div
                  class="popup__variants-item"
                  @click="setType('rtk_future_male')"
                  :class="{ active: type == 'rtk_future_male', waiting: isWaiting }"
                >
                  <img src="@/assets/img/souz.png" alt="" />
                  <p>Полет <br />в будущее</p>
                </div>
                <div
                  class="popup__variants-item"
                  @click="setType('rtk_past_male')"
                  :class="{ active: type == 'rtk_past_male', waiting: isWaiting }"
                >
                  <img src="@/assets/img/time.png" alt="" />
                  <p>Машина <br />времени</p>
                </div>
              </div>
              <div class="popup__variants" v-if="sex == 'female'">
                <div
                  class="popup__variants-item"
                  @click="setType('rtk_present_female')"
                  :class="{ active: type == 'rtk_present_female', waiting: isWaiting }"
                >
                  <img src="@/assets/img/anime.png" alt="" />
                  <p>Комикс <br />портрет</p>
                </div>
                <div
                  class="popup__variants-item"
                  @click="setType('rtk_future_female')"
                  :class="{ active: type == 'rtk_future_female', waiting: isWaiting }"
                >
                  <img src="@/assets/img/souz.png" alt="" />
                  <p>Полет <br />в будущее</p>
                </div>
                <div
                  class="popup__variants-item"
                  @click="setType('rtk_past_female')"
                  :class="{ active: type == 'rtk_past_female', waiting: isWaiting }"
                >
                  <img src="@/assets/img/time.png" alt="" />
                  <p>Машина <br />времени</p>
                </div>
              </div>
              <div class="popup__editor-button">
                <a
                  href="javascript:void(0)"
                  class="popup__editor-button-link"
                  @click="startWaiting"
                  v-if="!isWaiting"
                >
                  <i> <img src="@/assets/img/avatar-icon.svg" alt="" /> </i>Сгенерировать
                  аватар
                </a>
                <div class="popup__loading" v-if="isWaiting">
                  <div class="popup__loading-line">
                    <div
                      class="popup__loading-line-progress"
                      :style="`width: ${percent / 2}%`"
                    ></div>
                  </div>
                  <div class="popup__loading-title popup__loading-title--long">
                    Ожидайте, генерация может занять несколько минут...
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="popup__article-content" v-if="step == 4">
            <div class="popup__title popup__steps">Шаг 3/3</div>
            <div
              class="popup__title"
              :class="{
                'popup__title--small': activeContent.small_title,
                'popup__title--mini': activeContent.mini_title,
                'popup__title--small-mobile': activeContent.small_title_mobile,
                'popup__title--step-2': isStarted,
              }"
            >
              Ваш аватар <br />готов!
            </div>
            <div class="popup__subtitle">
              Вы можете скачать аватар или повторить генерацию заново, выбрав другие
              доступные фильтры для обработки
            </div>
            <div class="popup__result">
              <a
                :href="result"
                download
                class="popup__editor-button-link popup__editor-button-link--download"
              >
                <i> <img src="@/assets/img/download.svg" alt="" /> </i>Сохранить аватар
              </a>
              <a
                href="javascript:void(0)"
                @click="restart"
                class="popup__editor-button-link popup__editor-button-link--restart"
              >
                <i> <img src="@/assets/img/restart.svg" alt="" /> </i>повторить ещё раз
              </a>
            </div>
          </div>
        </div>
        <div class="popup__close" @click="$emit('close')"><span></span></div>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoEditor from "@/components/PhotoEditor.vue";
export default {
  name: "Popup_3",
  props: {
    activeContent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      step: 1,
      sex: "male",
      percent: 0,
      isStarted: false,
      selectedImage: null,
      error: false,
      isValidation: false,
      isWaiting: false,
      type: "rtk_future_male",
      file: null,
      result: null,
    };
  },
  components: {
    PhotoEditor,
  },
  mounted() {
    this.sendEvent("avatar");
  },
  methods: {
    restart() {
      this.step = 2;
      this.percent = 0;
      this.isStarted = false;
      this.selectedImage = null;
      this.error = false;
      this.isValidation = false;
      this.isWaiting = false;
      this.type = "rtk_future_male";
      this.file = null;
      this.result = null;
    },
    changeStep(step) {
      this.step = step;
    },
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.percent = 0;
        this.isStarted = false;
        this.error = false;

        try {
          this.isValidation = true;
          const validationResult = await this.validateImage(file);

          if (validationResult.status === 200) {
            this.isStarted = true;
            this.selectedImage = URL.createObjectURL(file);
            this.isValidation = false;
            this.step = 3;
            this.file = file;
          } else {
            this.error = true;
            this.percent = 0;
            this.isValidation = false;
          }
        } catch (error) {
          this.error = true;
          this.percent = 0;
          this.isValidation = false;
          console.error("Ошибка валидации: ", error);
        }
      }
    },
    async validateImage(file) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", file);

        const xhr = new XMLHttpRequest();

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            this.percent = Math.round((event.loaded / event.total) * 100); // Обновляем процент прогресса
          }
        };

        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr);
          } else {
            reject(new Error("Validation failed"));
          }
        };

        xhr.onerror = () => {
          reject(new Error("Network error"));
        };

        xhr.open("POST", "https://rtk-avatar-api-dev.way2ar.com/image/validate", true);
        xhr.send(formData);
      });
    },
    async uploadPhoto(file) {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(
        `https://rtk-avatar-api-dev.way2ar.com/image/upload?filter=${this.type}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload the image.");
      }

      const result = await response.json();
      return result.job_id;
    },
    async startWaiting() {
      this.isWaiting = true;
      const jobId = await this.uploadPhoto(this.file);
      await this.pollJobStatus(jobId);
    },
    async pollJobStatus(jobId) {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const response = await fetch(
          `https://rtk-avatar-api-dev.way2ar.com/image/${jobId}`
        );

        if (response.status === 202) {
          await delay(10000);
        } else if (response.status === 200) {
          const data = await response.json();
          this.setImage(data.result_url);
          this.step = 4;
          break;
        } else {
          throw new Error("Error while polling image status");
        }
      }
    },
    setImage(imageUrl) {
      this.result = imageUrl;
      this.isStarted = false;
      this.isWaiting = false;
    },
    setType(type) {
      if (!this.isWaiting) {
        this.type = type;
      }
    },
    setSex(sex) {
      if (!this.isWaiting) {
        this.sex = sex;
      }
    },
  },
  watch: {
    sex(val) {
      if (val == "male") {
        this.type = "rtk_future_male";
      }
      if (val == "female") {
        this.type = "rtk_future_female";
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/sass/functions.sass'
@import '@/assets/sass/popup.sass'
.popup__article--step-1
	@media screen and (max-width: 1000px)
		text-align: center
.popup__text--mini
	font-size: vw(14px)
	line-height: 1.5
	@media screen and (max-width: 1000px)
		font-size: vwm(15px)
.popup__container
	@media screen and (max-width: 1000px)
		padding-left: 0
.popup__subtitle
	&--orange
		color: #FFB49A !important
		margin-top: vw(21px)
		@media screen and (max-width: 1000px)
			margin-top: vwm(21px)
.popup__radio
	display: flex
	align-items: center
	&-item
		display: flex
		align-items: center
		margin-right: vw(20px)
		@media screen and (max-width: 1000px)
			margin-right: vwm(20px)
		&-title
			font-family: 'Tektur'
			color: #fff
			font-weight: 500
			font-size: vw(18px)
			@media screen and (max-width: 1000px)
				font-size: vwm(18px)
		&-round
			width: vw(18px)
			height: vw(18px)
			border-radius: 50%
			border: vw(2px) solid #FF4F12
			background-color: rgba(255, 79, 18, 0.3)
			display: flex
			align-items: center
			justify-content: center
			margin-right: 8px
			min-width: vw(18px)
			@media screen and (max-width: 1000px)
				min-width: vwm(18px)
				width: vwm(18px)
				border-width: vwm(2px)
				height: vwm(18px)
			span
				display: block
				width: vw(8px)
				height: vw(8px)
				background-color: #FF4F12
				border-radius: 50%
				opacity: 0
				@media screen and (max-width: 1000px)
					width: vwm(8px)
					height: vwm(8px)
		&.active
			span
				opacity: 1
.popup__result
	margin-top: vw(40px)
.popup__variants
	display: flex
	justify-content: space-between
	margin-top: vw(10px)
	color: #fff
	margin-bottom: vw(29px)
	width: vw(300px)
	@media screen and (max-width: 1000px)
		width: vwm(292px)
		margin-top: vwm(10px)
		margin-bottom: vwm(29px)
	&-item
		&.active
			img
				border: vw(4px) solid #FF4F12
		&.waiting
			opacity: 0.2
			&.active
				opacity: 1
	img
		width: vw(89px)
		height: vw(89px)
		border-radius: 50%
		border: vw(4px) solid transparent
		@media screen and (max-width: 1000px)
			width: vwm(85px)
			height: vwm(85px)
			border-width: vwm(4px) !important
	p
		font-size: vw(13px)
		text-align: center
		font-family: 'Rostelecom Basis'
		font-weight: 500
		margin-top: vw(5px)
		@media screen and (max-width: 1000px)
			font-size: vwm(13px)
			margin-top: vwm(5px)
.popup__error
	background: rgba(255, 63, 63, 0.2)
	padding: vw(10px) vw(32px) vw(9px) vw(40px)
	margin-bottom: vw(16px)
	border: 1px solid #FF4F12
	color: #fff
	font-size: vw(13px)
	border-radius: vw(15px)
	line-height: 1.35
	margin-top: vw(12px)
	background-image: url(../assets/img/error.svg)
	background-repeat: no-repeat
	background-size: vw(15px)
	background-position: vw(15px) vwm(10px)
	@media screen and (max-width: 1000px)
		padding: vwm(10px) vwm(32px) vwm(9px) vwm(40px)
		margin-top: vwm(12px)
		background-size: vwm(15px)
		background-position: vwm(15px) vwm(10px)
		font-size: vwm(13px)
		border-radius: vwm(15px)
		margin-bottom: vwm(16px)
.popup__loading
	&-title
		color: #fff
		text-align: center
		font-size: vw(16px)
		padding-top: vw(10px)
		@media screen and (max-width: 1000px)
			font-size: vwm(16px)
			padding-top: vwm(10px)
		&--long
			font-size: vw(12px)
			@media screen and (max-width: 1000px)
				font-size: vwm(12px)
	&-line
		width: 100%
		height: 5px
		background-color: rgba(255, 79, 18, 0.3)
		margin-top: vw(30px)
		position: relative
		&-progress
			width: 40% !important
			background-color: rgba(255, 79, 18, 1)
			height: 100%
			position: absolute
			top: 0
			left: 0
			animation: loaderPopup
			animation-duration: 2s
			animation-timing-function: linear
			animation-iteration-count: infinite
.popup__editor
	&-input
		display: none
	&-button
		margin-top: vw(14px)
		@media screen and (max-width: 1000px)
			width: 100%
			margin-top: vwm(14px) !important
		&-link
			width: vw(280px)
			height: vw(51px)
			background-color: #FF4F12
			text-align: center
			font-family: 'Tektur'
			font-weight: 500
			line-height: vw(51px)
			color: #fff
			font-size: vw(16px)
			letter-spacing: vw(1px)
			border-radius: vw(2px)
			backdrop-filter: blur(12.5px)
			filter: drop-shadow(0px 6.10394px 20.9278px rgba(0, 0, 0, 0.65))
			text-transform: uppercase
			display: flex
			align-items: center
			justify-content: center
			cursor: url('../assets/img/cursor_3.svg') 0 0, auto !important
			@media screen and (max-width: 1000px)
				width: 100% !important
				height: vwm(51px) !important
			&--download
				filter: none
			&--restart
				filter: none
				border: 2px solid #FF4F12
				backdrop-filter: none
				margin-top: vw(15px)
				background: transparent
				transition: all
				@media screen and (max-width: 1000px)
					margin-top: vwm(15px)
				&:hover
					background-color: #FF4F12
			@media screen and (max-width: 1000px)
				width: vwm(206px)
				height: vwm(45px)
				line-height: vwm(45px)
				font-size: vwm(16px)
			i
				margin-right: vw(10px)
				@media screen and (max-width: 1000px)
					margin-right: vwm(10px)
				img
					width: vw(14px)
					@media screen and (max-width: 1000px)
						width: vwm(14px)
.w-1140
	width: vw(1048px)
	@media screen and (max-width: 1000px)
		width: 100%
		padding-left: vwm(11.5px)
		padding-right: vwm(7px)
		.popup__title
			font-size: 10.072222vw !important
			line-height: 9.294444vw !important
		.popup__steps
			font-size: vwm(24px) !important
			margin-top: vwm(-4px) !important
			margin-bottom: vwm(7px) !important
		.popup__interactive
			height: vwm(352px)
		.popup__text--custom
			text-align: left
		.popup__title--step-2
			text-align: left
	@media screen and (min-width: 1000px)
		.popup__steps
			font-size: vw(24px) !important
			margin-top: vw(-4px) !important
			margin-bottom: vw(1px) !important
		.popup__article
			margin-left: vw(25px)
			width: vw(700px)
			margin-right: vw(-47px)
		.popup__close
			right: -4.7vw
		.popup__title
			font-size: 3.072222vw
			line-height: 2.894444vw
		.popup__text--custom
			line-height: 1.5
			font-size: vw(15px)
@keyframes loaderPopup
	0%
		left: 0%
	50%
		left: 60%
	100%
		left: 0%
</style>
