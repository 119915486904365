var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup__bg"},[_c('div',{staticClass:"popup w-1140"},[_c('div',{staticClass:"popup__container"},[_c('div',{staticClass:"popup__interactive"},[_c('Connections',{attrs:{"slides":_vm.slides,"active":_vm.active},on:{"change":function($event){_vm.isStarted = true},"set":_vm.setSlide}})],1),_c('div',{staticClass:"popup__article",attrs:{"data-lenis-prevent":""}},[(!_vm.isStarted)?_c('div',{staticClass:"popup__article-content"},[_c('div',{staticClass:"popup__title",class:{
              'popup__title--small': _vm.activeContent.small_title,
              'popup__title--mini': _vm.activeContent.mini_title,
              'popup__title--small-mobile': _vm.activeContent.small_title_mobile,
              'popup__title--mini-mobile': _vm.activeContent.mini_title_mobile,
            },domProps:{"innerHTML":_vm._s(_vm.activeContent.title)}}),_c('div',{staticClass:"popup__subtitle",domProps:{"innerHTML":_vm._s(_vm.activeContent.info)}}),_c('div',{staticClass:"popup__text",domProps:{"innerHTML":_vm._s(_vm.activeContent.text)}})]):_vm._l((_vm.slides),function(slide){return _c('div',{key:slide.id,staticClass:"popup__article-content"},[(slide.id == _vm.active)?[_c('div',{staticClass:"popup__title popup__title--step",class:{
                  'popup__title--small': _vm.activeContent.small_title,
                  'popup__title--mini': _vm.activeContent.mini_title,
                  'popup__title--small-mobile': _vm.activeContent.small_title_mobile,
                  'popup__title--mini-mobile': _vm.activeContent.mini_title_mobile,
                  'popup__title--step-2': _vm.isStarted,
                }},[_vm._v(" "+_vm._s(_vm.active)+"/"+_vm._s(_vm.slides.length)+" ")]),_c('div',{staticClass:"popup__title",class:{
                  'popup__title--small': _vm.activeContent.small_title,
                  'popup__title--mini': _vm.activeContent.mini_title,
                  'popup__title--small-mobile': _vm.activeContent.small_title_mobile,
                  'popup__title--mini-mobile': _vm.activeContent.mini_title_mobile,
                  'popup__title--step-2': _vm.isStarted,
                }},[_vm._v(" "+_vm._s(slide.name)+" ")]),_c('div',{staticClass:"popup__text-heading"},[_vm._v(" "+_vm._s(slide.title)+" ")]),_c('div',{staticClass:"popup__text popup__text--custom",domProps:{"innerHTML":_vm._s(slide.text)}}),_vm._m(0,true)]:_vm._e()],2)})],2),_c('div',{staticClass:"popup__close",on:{"click":function($event){return _vm.$emit('close')}}},[_c('span')])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dinasty__button dinasty__button--large"},[_c('a',{attrs:{"href":"https://anketolog.ru/s/858194/t5znH4yX","target":"_blank"}},[_vm._v("рассказать о своей династии")])])
}]

export { render, staticRenderFns }