<template>
	<div class="quiz__window">
		<div class="quiz__window-content">
			<div class="quiz__window-title quiz__window-title--false">Истина где-то рядом</div>
			<div class="quiz__window-text">Чего-то не знать — не страшно, страшнее ничем не интересоваться. В любом случае вы  узнали много новой информации и теперь можете проверить, что из этого вы смогли запомнить.</div>
			<div class="quiz__window-bottom">
				<a href="javascript:void(0)" class="quiz__window-btn">попробовать ещё раз</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Quiz_4_1",
	}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/quiz.sass'
.quiz__window-bottom 
	margin-top: vw(40px)
	display: flex
	justify-content: space-around
.quiz__window-text
	margin-top: vw(40px)
</style>