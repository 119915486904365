<template>
	<div class="popup__wrapper">
		<Popup_1></Popup_1>
	</div>
</template>

<script>
	import Popup_1 from '@/components/Popup_1.vue'
export default {
	name: 'Modals',
	components: {
		Popup_1
	}
}
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/popup.sass'
	.popup__wrapper
		background-color: #333
		width: 100vw
		height: 2000px
</style>
