<template>
	<div class="popup__bg popup__bg--final">
		<div class="popup popup__final">
			<div class="popup__final-content">
				<div class="popup__final-title">История технологий продолжается. Цифровое будущее создаём мы.</div>
				<div class="popup__final-subtitle">С любовью, ваш «Ростелеком» ❤️</div>
			</div>
			<div class="popup__final-share">
				<div class="burger__socials">
					<div class="burger__socials-title">Поделиться:</div>
					<div class="burger__socials-content">
						<a href="javascript:void(0)" class="burger__socials-link burger__socials-link--vk" data-url="https://xn--b1aajah6aiuf6i5a.xn--e1aapchhekkk.xn--p1ai/" data-title="Связь времён: от первобытных сигнальных костров до цифрового завтра!" @click="share('vk')" data-image="https://xn--b1aajah6aiuf6i5a.xn--e1aapchhekkk.xn--p1ai/1200.jpg" data-type="vk" ref="vk"></a>
						<a href="javascript:void(0)" class="burger__socials-link burger__socials-link--telegram" data-url="https://xn--b1aajah6aiuf6i5a.xn--e1aapchhekkk.xn--p1ai/" data-title="Связь времён: от первобытных сигнальных костров до цифрового завтра!" @click="share('telegram')" data-type="telegram" data-image="https://xn--b1aajah6aiuf6i5a.xn--e1aapchhekkk.xn--p1ai/1200.jpg" ref="telegram"></a>
					</div>
				</div>
			</div>
			<div class="popup__final-logo"><a href="https://msk.rt.ru/" target="_blank"><img src="@/assets/img/logo-4.svg" alt=""></a></div>
		</div>
	</div>
</template>

<script>
	import JSShare from "js-share";
	export default {
		name: "Popup_11",
		mounted() {
			this.sendEvent('finish');
		},
		methods: {
			share(soc) {
				if (soc == 'vk') {
					this.sendEvent('share_vk');
				}
				if (soc == 'telegram') {
					this.sendEvent('share_tg');
				}
				JSShare.go(this.$refs[soc]);
			},
		}
	}
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/popup.sass'
</style>