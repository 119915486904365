<template>
	<div>
		<template v-if="!isDone">
			<template v-if="!currentResult">
				<Quiz_1 v-if="step == 0" :quiz="quiz" @start="startQuiz"></Quiz_1>
				<Quiz_2 v-if="step > 0 && currentStep && currentStep.type == 2" :question="quiz.items[step-1]" :total="totalSteps" :step="step" @next="setAnswer"></Quiz_2>
				<Quiz_3 v-if="step > 0 && currentStep && currentStep.type == 1" :question="quiz.items[step-1]" :total="totalSteps" :step="step" @next="setAnswer"></Quiz_3>
				<Quiz_4 v-if="step > 0 && currentStep && currentStep.type == 3" :question="quiz.items[step-1]" :total="totalSteps" :step="step" @next="setAnswer"></Quiz_4>
				<Quiz_5 v-if="step > 0 && currentStep && currentStep.type == 4" :question="quiz.items[step-1]" :total="totalSteps" :step="step" @next="setAnswer"></Quiz_5>
			</template>
			<template v-else>
				<AnswerCorrect :result="currentResult" v-if="currentResult.correct == true" :text="quiz.items[step-1].correct_text" :image="quiz.items[step-1].image" @next="next"></AnswerCorrect>
				<AnswerWrong :result="currentResult" v-if="currentResult.correct == false" :text="quiz.items[step-1].wrong_text" :image="quiz.items[step-1].image" @next="next"></AnswerWrong>
			</template>
		</template>
		<template v-else>
			<Result :result="result_text" :fail="quiz.fail_text" :image="quiz.image" :success="quiz.success_text" @restart="restart"></Result>
		</template>
	</div>
</template>

<script>
	import Quiz_1 from '@/components/Quiz/Quiz_1.vue'
	import Quiz_2 from '@/components/Quiz/Quiz_2.vue'
	import Quiz_3 from '@/components/Quiz/Quiz_2_1.vue'
	import Quiz_4 from '@/components/Quiz/Quiz_2_2.vue'
	import Quiz_5 from '@/components/Quiz/Quiz_2_5.vue'
	import AnswerCorrect from '@/components/Quiz/Quiz_3.vue'
	import AnswerWrong from '@/components/Quiz/Quiz_3_1.vue'
	import Result from '@/components/Quiz/Quiz_4.vue'
	export default {
		name: "Quiz",
		data() {
			return {
				step: 0,
				answers: [],
				currentResult: null,
				isDone: false,
			}
		},
		components: {
			Quiz_1,
			Quiz_2,
			Quiz_3,
			Quiz_4,
			Quiz_5,
			AnswerCorrect,
			AnswerWrong,
			Result,
		},
		props: {
			quiz: {
				type: Object,
				required: true,
			}
		},
		methods: {
			startQuiz() {
				this.step = 1;
			},
			setAnswer(answer) {
				this.answers.push(answer);
				this.currentResult = answer;
			},
			restart() {
				this.step = 0;
				this.answers = [];
				this.currentResult = null;
				this.isDone = false;
			},
			next() {
				if (this.step !== this.totalSteps) {
					this.step++;
					this.currentResult = null;
				} else {
					this.isDone = true;
				}
			},
		},
		computed: {
			totalSteps() {
				return this.quiz.items.length;
			},
			currentStep() {
				return this.quiz.items[this.step-1];
			},
			result_text() {
				const counts = this.answers.reduce((acc, current) => {
					if (current.correct) {
						acc.correctCount++;
					} else {
						acc.incorrectCount++;
					}
					return acc;
				}, { correctCount: 0, incorrectCount: 0 });

				return counts.correctCount > counts.incorrectCount ? 'correct' : 'incorrect';
			}
		}
	}
</script>