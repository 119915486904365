<template>
	<div class="quiz__window" :class="{'quiz__window--image': quiz.image}"  :style="`background-image: url(${getImage})`">
		<div class="quiz__window-content">
			<div class="quiz__window-title" :class="{'quiz__window-title--small': quiz.title_small}"><h2>{{quiz.title}}</h2></div>
			<div class="quiz__window-text">{{quiz.text}}</div>
			<div class="quiz__window-bottom">
				<a href="javascript:void(0)" class="quiz__window-btn" @click="$emit('start')">начнём</a>
			</div>
		</div>
		<div class="quiz__window-image" v-if="quiz.showMax">
			<img src="@/assets/img/max.png" alt="">
		</div>
	</div>
</template>

<script>
	export default {
		name: "Quiz_1",
		props: {
			quiz: {
				type: Object,
				required: true,
			}
		},
		computed: {
			getImage() {
				if (this.quiz.image) {
					return require('../../assets/img/quiz/'+this.quiz.image+'.jpg');
				} else {
					return null;
				}
			}
		}
	}
</script>

<style lang="sass">
@import '@/assets/sass/quiz.sass'

</style>