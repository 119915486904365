<template>
	<div class="popup__bg">
		<div class="popup quiz-only">
			<div class="popup__container">
				<div class="popup__interactive">
					<Quiz :quiz="activeContent.quiz"></Quiz>
				</div>
				<div class="popup__close" @click="$emit('close')" ><span></span></div>
			</div>
		</div>
	</div>
</template>

<script>
	import Quiz from '@/components/Quiz/Quiz.vue'
	export default {
		name: "Popup_3",
		components: {
			Quiz,
		},
		props: {
			activeContent: {
				type: Object,
				required: true,
			}
		},
	}
</script>

<style lang="sass">
	@import '@/assets/sass/functions.sass'
	@import '@/assets/sass/popup.sass'
</style>