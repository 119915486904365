<template>
	<div class="quiz__window">
		<div class="quiz__window-content">
			<div class="quiz__window-title">Вопрос {{step}}/{{total}}</div>
			<div class="quiz__window-text" v-html="question.question"></div>
			<div class="quiz__window-items">
				<div class="quiz__window-item" v-for="(answer, index) in question.answers" :key="index" @click="setAnswer(index)">
					<div class="quiz__window-checkbox" :class="{'quiz__window-checkbox--active': choise == index}">
					</div>
					<div class="quiz__window-label">
						{{answer.text}}
					</div>
				</div>
			</div>
			<div class="quiz__window-bottom">
				<a href="javascript:void(0)" class="quiz__window-btn" @click="submit">продолжить</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Quiz_2",
		data() {
			return {
				choise: null,
			}
		},
		props: {
			question: {
				type: Object,
				required: true,
			},
			step: {
				type: Number,
				required: true,
			},
			total: {
				type: Number,
				required: true,
			},
		},
		methods: {
			setAnswer(index) {
				this.choise = index;
				this.tippy[0].destroy();
			},
			submit() {
				if (this.choise !== null) {
					this.$emit('next', this.question.answers[this.choise]);
					this.choise = null;
					this.initTippy();
				}
			}
		}
	}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/quiz.sass'
.quiz__window-text
	margin-top: vw(40px) !important
.quiz__window-bottom 
	margin-top: vw(40px)
.quiz__window-checkbox
	min-width: 3.0555555556vw
.quiz__window-item
	width: 38%
	margin-bottom: 1vw
.quiz__window-items
	padding: 0 vw(60px) 0 vw(60px)
	display: flex
	max-width: 90%
	flex-wrap: wrap
.quiz__window-btn--disabled
	opacity: 0.4
</style>