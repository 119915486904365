<template>
	<div class="quiz__window" :class="{'quiz__window--image': question && question.image}"  :style="`background-image: url(${getImage})`">
		<div class="quiz__window-content">
			<div class="quiz__window-title">Вопрос {{step}}/{{total}}</div>
			<div class="quiz__window-text" v-html="question.question"></div>
			<div class="quiz__window-bottom">
				<a href="javascript:void(0)" class="quiz__window-btn" :class="{'quiz__window-btn--true': index == 0, 'quiz__window-btn--false': index == 1}" v-for="(answer, index) in question.answers" :key="index" @click="setAnswer(index)">{{answer.text}}</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Quiz_2_2",
		data() {
			return {
				choise: null,
			}
		},
		props: {
			question: {
				type: Object,
				required: true,
			},
			step: {
				type: Number,
				required: true,
			},
			total: {
				type: Number,
				required: true,
			},
		},
		methods: {
			setAnswer(index) {
				this.choise = index;
				this.$emit('next', this.question.answers[this.choise]);
			},
		},
		computed: {
			getImage() {
				if (this.question.image) {
					return require('../../assets/img/quiz/'+this.question.image+'.jpg');
				} else {
					return null;
				}
			}
		}
	}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/quiz.sass'
.quiz__window-bottom 
	margin-top: vw(40px)
	display: flex
	justify-content: space-around
</style>