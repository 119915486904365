import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '../views'
import Modals from '../views/modals.vue'
import Quiz from '../views/quiz'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    meta: {
      title: 'Главная',
    },
  },
  {
    path: '/Quiz',
    component: Quiz,
    meta: {
      title: 'Quiz',
    },
  },
  {
    path: '/modals',
    component: Modals,
    meta: {
      title: 'Модалки',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  routes
})

export default router
